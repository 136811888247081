
html, body, #root, .App {
  height: 100%;
}

h3 {
  color: #345E87;
}

p {
  color:#485775;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  margin-top:5px;
  margin-bottom:10px !important;
  font-size:13px;
}

.paragraphText {
  font-size:16px;
}

.topHeader {
  height:30px;
  background-color: #264763;
}

.bodyRightHeaders {
  margin-top:20px;
  color:rgba(0,136,204,0.8);
  font-size:22px;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
}

.base {
  text-align: center;
  width: 100vw;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}

.topSpace {
  height:100px;
}

.topGap80 {
  height: 80px;
  background-color: #ffffff;
  /*background-color: #345E87;*/
}

.logoBack {
  height:40px;
  margin-left:30px;
}

.logoBack1 {
  height:40px;
  margin-left:30px;
}

.logoText {
  padding-top:58px;
  margin-top:-10px;
  margin-left:30px;
  height:33px
}

.whiteText {
  color:white;
}

.grayText {
  color:gray;
}

.blackText {
  color:black;
}

.topGap80TextPosition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logoBackColor {
  /*background-color: white;*/
  min-width:200px;
}

.topGap80TextStyle {
  color:#08c;
  font-style: italic;
  font-size:24px;
  padding-right:30px;
  padding-left:50px;
}

.fontsize30 {
  font-size:30px;
}

.fontsize40 {
  font-size:40px;
}

.loginForm {
  text-align: left;
  margin-top:5%;
  margin-left:40px;
  margin-right:40px;
  font-size: 22px;
}

.textField {
  margin-top:15px;
  color: black;
}

.PrivateSwitchBase-root-5 {
  padding:0;
}

.whiteLogo {
  height:20px
}

.fontSize22 {
  font-size: 15px;
}

.centerBody {
  background: url('images/CityScape.jpg');
  background-size: cover;
  height:100%;
  /*min-height: 88.8vh;*/

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.textEmailBox {
  font-size: 12px;
  padding-left:5px;
}

.loginBox {
  margin-top: -5%;
  width:460px;
  background-color: rgba(34, 125, 180, 0.86);
}

.spaceBetween {
  width:10px;
}

.container {
  height: 200px;
  width: 200px;
  position: relative;
}

.passwordResetText{
  font-size: 13px;
  color: white;
}

.passwordResetText:hover {
  text-decoration: underline;
  cursor:pointer;
}

.passwordResetButton {
  border:0.15em solid #50f1a5;
  background-color: #50f1a5;
  height: 30px;
  width: 80px;
  color:#345e87;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
  font-weight: bold;
  font-family: "Lato Black";
}

.passwordResetButton2 {
  padding-bottom: 3px;
  font-weight: 700;
  font-family: "Lato Black";
  text-align: left;
  font-style: italic;
}

.passwordResetButton:hover {
  color: white;
  border-color: white;
  cursor:pointer;
}

.loginHeader{
  color:#50f1a5;
  text-align: center;
}

.loginBoxFooter {
  margin-top:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.portalFunctionBarLargeLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.portalFunctionBodyLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height:100%;
  width:100%;
}

.portalFunctionBarRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width:100%;
}

.profileDropDown {
  height:30px;
  position:absolute;
  color:white;
  background-color: #04cb71;
  width:15%;
  margin-top:110px;
  margin-left:20px;
  right:0;
}

.handIcon{
  cursor:pointer;
}

.profileDropDown:hover {
  background-color: #5ff3b0;
  cursor:pointer;
}

.portalFunctionBodyRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height:100%;
  width:100%;
}

.infoBoxLarge {
  height:80px;
  width:250px;
  color:white;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  margin-top:15px;
  margin-right:10px;
  margin-bottom:20px;
}

.backgroundINVWhitePicture {
  background-color: #ffffff;

}

.baseShadow {
  box-shadow: 0px 5px 6px 1px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 5px 6px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 5px 6px 1px rgba(0,0,0,0.75);
}

.backgroundINVblue {
  background-color: #345e87;
}

.backgroundINVLightBlue {
  background-color: #227DB4;
}

.backgroundINVGreen {
  /*background-color: #50F0A5;*/
  background-color: #269f68;
}

.colorBlack {
  color: black;
}

.borderINVblue {
  border-color: #345e87;
}

.borderINVLightBlue {
  border-color: #227DB4;
}

.borderINVGreen {
  border-color: #269f68;
}

.fontSize27 {
  font-size: 27px;
}

.fontSize13 {
  font-size: 13px;
}

.fontSize16 {
  font-size: 16px;
}

.fontColorGreen {
  color:#269f68;
}

.fontColorINVGreen{
  color:#50f1a5;
}

.backgroundINVWhite {
  background-color: white;
}

.backgroundINVYellow {
  background-color: #fde085;
}

.backgroundINVOrange {
  background-color: #f6b127;
}

.backgroundINVBrickRed {
  background-color: #8a3333;
}

.backgroundINVLightRed {
  background-color: #b07171;
}

.backgroundINVLightGreen {
  background-color: #04cb71
}

.noticeBarHeader {
  height: 7%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size:24px;
  z-index: 1;
  position:relative;
}

.height100 {
  height:100%
}

.backgroundBlueGraident {
  background: rgb(0,189,255);
  background: linear-gradient(90deg, rgba(0,189,255,1) 3%, rgba(2,0,36,1) 100%, rgba(9,9,121,1) 100%);
}

.textNewBlue {
  color: rgba(0,136,204,0.8);
}

.noticeBarBody {
  height: 80.1%;
  width:100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.fileSelection {
  background-color: white;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  margin-top: -30px;
  min-width: 200px;
  border: 1px solid black;
  border-radius:5px;
}

h4 {
  color:black;
  text-align: left;
  padding-left:10px;
  margin:0px;
  padding:5px;
}

h4:Hover {
  color:white;
  background-color: #04cb71;
  margin:0px;
  padding:5px;
  line-height: normal;
}

insideBody {
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;

  overflow: auto;
}

.bodyStepsRight {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  height:100%;
  overflow: auto;
}

.noticeBarFooter {
  height: 60px;
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}

.moreItems {
  /*margin-top:0px*/
  font-size:20px;
}

.infoInside {
  height:100%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infoHeader {
  height:70%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.infoFooter {
  height:30%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.settingsIcon {
  font-size: 30px;
}

.welcomeRight {
  height:100%;
  width:30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcomeRight {
  cursor:pointer;
}

.welcomeLeft {
  height:100%;
  width:70%;
  margin-left:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.tabText {
  font-size:20px;
}

.tabTextBlack {
  color:black;
  font-size:20px;
}

.tabBackgroundLight {
  background-color: white;
}

.tabBackgroundDark {
  background-color: #dadadd;
}

.tabTextColor {

}

.leftBody {
  height:80%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.leftBodyFooter {
  height:5%;
}

.rightBody {
  height:100%;
  width: 350px;
  margin-top: -55px;
  /*margin-left:20px;*/
}

.infoBoxFullLeftWidth {
  height:80px;
  width:100%;
  color:white;
  border-color: transparent;
  border-width: 2px;
  border-style: solid;
  margin-top:15px;
  margin-bottom:20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.appIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100px;
  margin-right:20px;
  margin-left:30px;
  margin-top:30px;
}

.appIcon:hover {
  color: rgba(255, 255, 255, 0.56);
  cursor:pointer;
}

.adminContainer{
  position: relative;
  width: 95%;
  height: 95%;
}

.userSetupTitle{
  font-size: 20px;
  text-align: center;
  color:#227DB4;
}

.userBox{
  text-align: left;
  /*border: solid #a0a0a0 2px;*/
  position: absolute;
  top:0px;
  left:0px;
  width: 48%;
}

.applicationBox{
  text-align: left;
  /*border: solid #a0a0a0 2px;*/
  position: absolute;
  top:0px;
  right:0px;
  width: 48%;
}

.userInfo{
  font-size: 14px;
  font-weight: bold;
  padding: 2px;
  margin: auto;
  display: inline-block;
}

.actionButton {
  border: 2px solid #50f1a5;
  justify-content: center;
  padding: 5px 10px;
  background-color: #50f1a5;
}

.actionButton:hover {
  border-color: white;
  cursor:pointer;
}

.userActionButton {
  font-size: 11px;
  font-weight: bold;
  color: #227DB4;
  text-align: center;
  border: 2px solid #a0a0a0;
  padding: 3px;
  margin: 5px;
  display: inline-block;
  width: 100px;
}

.userActionButton:hover {
  background-color: #227DB4;
  color: white;
  cursor:pointer;
}

.IconStyle {
  height:45px;
}

.IconStyleUAT {
  height:45px;
  filter: hue-rotate(315deg);
}

.IconStyleQA {
  height:45px;
  filter: hue-rotate(90deg);
}

.IconStyleDev {
  height:45px;
  filter: hue-rotate(150deg);
}

.IconSpin {}

.IconStyleLearnMore {
  height:45px;
  opacity:0.18;
}

.IconStyleLearnMoreUAT {
  height:45px;
  opacity:0.18;
  filter: hue-rotate(315deg);
}

.IconStyleLearnMoreQA {
  height:45px;
  opacity:0.18;
  filter: hue-rotate(90deg);
}

.IconStyleLearnMoreDEV {
  height:45px;
  opacity:0.18;
  filter: hue-rotate(150deg);
}

.appIconUpper {
  font-size: 20px;
  width:180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appIconUpper:hover {
  overflow: visible;
  white-space: normal;
}

.appIconLower {
  font-size: 12px;
  width:180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appIconLower:hover {
  overflow: visible;
  white-space: normal;
}

.appIconUpperLearnMore {
  font-size: 20px;
  width:180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(125, 136, 157, 0.53);
}

.appIconUpperLearnMore:hover {
  overflow: visible;
  white-space: normal;
}

.appIconLowerLearnMore {
  font-size: 12px;
  width:180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(125, 136, 157, 0.53);
}

.appIconLowerLearnMore:hover {
  overflow: visible;
  white-space: normal;
}

.footer {
  height:100px;
}

.error {
  background-color: #E79C07;
  padding-top:5px;
  padding-bottom:8px;
  width:100%;
}

.leftBodyColumn {
  padding-left:30px;
  width:100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.prodTabsChangeColorActive {
  color:#0087CC !important;
  background-color:white !important;
}

.testTabsChangeColorActive {
  color: #009B63 !important;
  background-color: white !important;
}

.qaTabsChangeColorActive {
  color: #cd4cdf !important;
  background-color: white !important;
}

.devTabsChangeColorActive {
  color: #FF0000 !important;
  background-color: white !important;
}

.prodTabsChangeColorOff {
  color:#485775 !important;
  background-color:#dadadd !important;
}

.testTabsChangeColorOff {
  color: #485775 !important;
  background-color: #dadadd !important;
}

.qaTabsChangeColorOff {
  color: #485775 !important;
  background-color: #dadadd !important;
}

.devTabsChangeColorOff {
  color: #485775 !important;
  background-color: #dadadd !important;
}

.testBodyHide {
  display:none;
}

.testBodyShow {
  display:flex;
}

.qaBodyHide {
  display:none;
}

.qaBodyShow {
  display:flex;
}

.devBodyHide {
  display:none;
}

.devBodyShow {
  display:flex;
}

.prodBodyHide {
  display:none;
}

.prodTabShow {
  display:flex;
}

.iconContainer {
  margin-top:20px;
  padding:5px;
  cursor:pointer;
}

.iconContainer:hover {
  cursor:pointer;
  border-radius: 3px;
  border: 1px solid rgba(38, 194, 185, 0.56);
  /*background-color: #5df3dc;*/
}

.iconContainer:hover .IconSpin {
}

.learnMoreOverlay {
  padding:10px;
  margin-top:-50px;
  margin-left:-50px;
  position:absolute;
  height:55px;
  width:250px;
  background-color: rgb(253, 253, 253);
  display:none;
  color:black;
  border-top: 7px solid;
  border-color: #46ff46;
  text-align: left;
  font-size:12px;
}

.learnMoreButton {
  width:100px;
  height:25px;
  background-color:#46ff46;
  color:black;
  font-size:15px;
  text-align:center;
  padding-top:5px;
  margin-top: 20px;
  margin-left: 65px;
}

.LearnMoreEllipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.learnMoreShadow {
  box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.42);
  -webkit-box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.42);
  -moz-box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.42);
}

.learnMore {
  margin-top:20px;
  padding:5px;
  cursor:pointer;
}

.learnMore:hover {
  cursor:pointer;
  border-radius: 3px;
  /*border: 1px solid rgba(38, 194, 185, 0.56);*/
  /*background-color: #5df3dc;*/
}

.learnMore:hover .learnMoreOverlay {
  display:block;
}


.tabs  {
  height:50px;
  width:220px;
  margin-top:80px;
  cursor:pointer;
}

.formatRight  {
  height:30px;
  width:220px;
  margin-top:15px;
}

.appBody  {
  height: 100%;
  width: 200%;
  color: #485775;
}

.appBodyRight  {
  height:100%;
  width:95%;
  color: #ffffff;
  /*margin-right:20%;*/
  margin-left:25%;
}

.dotPatternBackground {
  background: url('images/DotPattern_1920x1080.png') no-repeat;
}

.dotPatternBackgroundTab {
  background: url('images/DotPattern_1920x1080.png') no-repeat;
}

.portalPagePartition {
  background: url('images/CityscapeBackground_Bar_1920x438.jpg') no-repeat;
  /*background-size: cover;*/
  height:100%;
  width:100%;
}

.portalPagePartitionSmoke {
  /*background-color: rgba(166, 166, 166, 0.56);*/
  height:100%;
  width:100%;
}

.logoDisplay {
  display:none;
}

.portalPagePartitionMargin {
  height:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-right:15px;
  margin-left:5%;
}


.portalPartitionLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height:100%;
  width:80%;
  margin-right:7%;
}

.portalPartitionRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  height:100%;
  width:33%;
}

.checkbox {
  width: 100%;
  margin: 5px auto;
  position: relative;
  display: block;
}

.checkbox input[type="checkbox"] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
}

.checkbox label {
  position: relative;
}

.checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 15px;
  height: 15px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 2px solid white;
}
.checkbox label:after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 8px;
  left: 8px;
}
.checkbox input[type="checkbox"]:checked ~ label::before {
  color: white;
}

.checkbox input[type="checkbox"]:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.checkbox label {
  min-height: 34px;
  display: block;
  padding-left: 30px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  vertical-align: sub;
  font-size:18px;
}

.checkbox label span {
  position: absolute;
  top: 35%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: 0;
}

@media only screen and (max-width:1345px) {
  .portalPartitionLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height:100%;
    width:70%;
  }

  .portalPartitionRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    height:100%;
    width:25%;
  }
}

@media only screen and (max-width:1083px) {
  .portalPartitionLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height:100%;
    width:70%;
  }

  .portalPartitionRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    height:100%;
    width:30%;
  }
}

@media only screen and (max-width:970px) {
  /*.portalPartitionLeft {*/
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*  align-items: flex-start;*/
  /*  justify-content: flex-start;*/
  /*  height:100%;*/
  /*  width:60%;*/
  /*}*/

  .portalPartitionRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    height:100%;
    width:35%;
  }

  /*.leftBodyColumn {*/
  /*  padding-left:0px;*/
  /*  width:95%;*/
  /*  flex-wrap: wrap;*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  align-items: flex-start;*/
  /*  justify-content: space-between;*/
  /*}*/
}

@media only screen and (max-width: 684px) {
  .loginBox {
    margin-top:-30%;
    background-color: rgba(34,125,180,.97);;
  }

  .logoDisplay {
    display:block;

  }

  .logoText {
    padding-top:58px;
    margin-bottom:10px;
    margin-left:23%;
    height:33px;
    width:100%;
  }

  .logoBackColor {
    display:none;
  }

  .centerBody {
    min-height: 77vh;
  }
}

@media only screen and (max-width: 540px) {
  .loginBox {
    height: 77vh;
    margin-top:0;
    width:100%;
    background-color: rgba(34,125,180,.97);;
  }

  .centerBody {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: #485775;
    min-height: 77vh;
  }
}

@media only screen and (max-width: 493px) {
  .topGap80TextStyle {
    /*color:white;*/
    font-style: italic;
    font-size: 21px;
  }


}

  @media only screen and (max-width: 1101px) {
    .rightBody {
      height: 100%;
      width: 330px;
      margin-top: -55px;
      margin-left: 20px;
      display: none;
    }

    .portalPagePartitionMargin {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      margin-right: 0px;
      margin-left: 5%;
    }

    .portalPartitionLeft {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      margin-right: 5%;
    }

    .tabs {
      height: 50px;
      width: 220px;
      margin-top: 50px;
      cursor:pointer;
    }
  }
  @media only screen and (max-width: 1354px) {
    .bodyRightHeaders {
      margin-top: 20px;
      color: rgba(0, 136, 204, 0.8);
      font-size: 20px;
      text-align: left;
      margin-left: 15px;
      margin-right: 15px;
    }

    .paragraphText {
      font-size: 14px;
    }
  }

.scroller {
  overflow-y: auto;
  scrollbar-color: #505050 #000000;
  scrollbar-width: thin;
}

  .scroller::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  .scroller::-webkit-scrollbar-track-piece {
    background-color: #000000;
  }

  .scroller::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: #505050;
  }

